.problem-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -10px;

  li {
    display: inline-block;
    margin: 0 15px;
    transform: translateY(200px);
    opacity: 0;
    transition: 0.8s ease all;

    img {
      height: 100px;
    }

    &:nth-child(1) {
      transition-delay: 0.8s;
    }
    &:nth-child(2) {
      transition-delay: 1s;
    }
    &:nth-child(3) {
      transition-delay: 1.2s;
    }
  }
}

.active {
  .problem-icons {
    li {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

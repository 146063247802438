h1 {
  font-size: 130px;
  text-transform: uppercase;
  line-height: 0.8;
  margin-bottom: 40px;

  span {
    display: block;
    opacity: 0.5;
    font-size: 75%;
  }
}
h2 {
  font-size: 100px;
  text-transform: uppercase;
  line-height: 0.8;
  margin-bottom: 40px;

  span {
    display: block;
    opacity: 0.5;
    font-size: 75%;
  }
}
h3 {
  font-size: 76px;
  text-transform: uppercase;
  line-height: 0.8;
  margin-bottom: 50px;
  margin-top: 20px;

  span {
    display: block;
    opacity: 0.5;
    font-size: 75%;
  }
}

.text-center {
  text-align: center;
}

.plain-list {
  list-style: none;
  font-size: 24px;
  font-weight: 700;
  color: rgba(255,255,255,0.7);
  margin: 0;
  padding: 0;

  li {
    position: relative;
    margin-bottom: 15px;
    padding-left: 40px;
    transform: translateY(200px);
    opacity: 0;
    transition: 0.8s ease all;

    &:before {
      content: "❯";
      position: absolute;
      left: 10px;
      top: 4px;
      font-size: 18px;
      color: #fff;
    }
    &:nth-child(1) {
      transition-delay: 0.8s;
    }
    &:nth-child(2) {
      transition-delay: 1s;
    }
    &:nth-child(3) {
      transition-delay: 1.2s;
    }
    &:nth-child(4) {
      transition-delay: 1.4s;
    }
    &:nth-child(5) {
      transition-delay: 1.6s;
    }
    &:nth-child(6) {
      transition-delay: 1.8s;
    }
    &:nth-child(7) {
      transition-delay: 2s;
    }
    &:nth-child(8) {
      transition-delay: 2.2s;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 35px;
  }
}

.active {
  .plain-list {
    li {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

a {
  color: #fff;
  text-decoration: none;

  &:hover {
    color: rgba(255,255,255,0.7);
  }
}

.chart {
  position: relative;
  height: 350px;
  border-bottom: 2px solid rgba(255,255,255,0.7);

  .chart-desc {
    font-weight: 600;
    font-size: 13px;
    position: absolute;
    top: 10px;
    right: 0;
    text-align: right;
    width: 450px;
    padding-right: 44px;

    > span {
      display: block;
      color: rgba(255,255,255,0.7);
      line-height: 1.1;
      margin-bottom: 7px;

      &:first-child {
        color: rgba(255,255,255,0.8);
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 700;
      }
    }
  }

  .demand {
    position: absolute;
    bottom: 0;
    left: 50px;
    background: rgba(255,255,255,0.7);
    height: 0;
    width: 200px;
    transition: 1s ease all;
    transition-delay: 0.8s;
  }
  .supply {
    position: absolute;
    bottom: 0;
    left: 300px;
    background: rgba(255,255,255,0.7);
    height: 0;
    width: 200px;
    transition: 1s ease all;
    transition-delay: 0.8s;
  }
  .supply,
  .demand {
    span {
      font-size: 28px;
      font-weight: 700;
      display: block;
      text-align: center;
      padding-top: 10px;
      opacity: 0;
      transition: 0.8s ease all;
      transition-delay: 1.2s;
      color: #89d1d1;

      span {
        font-size: 14px;
        padding-top: 0;
        color: #a3ddde;
      }
    }
  }
}

.active {
  .chart {
    .demand {
      height: 100%;
    }
    .supply {
      height: 24%;
    }
    .supply,
    .demand {
      span {
        opacity: 1;
      }
    }
  }
}

.market-channels {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;

  > li {
    display: inline-block;
    height: 180px;
    width: 180px;
    border: 3px solid rgba(255,255,255,0.5);
    border-radius: 250px;
    text-align: center;
    vertical-align: top;
    margin: 0 12px;
    transform: translateY(200px);
    opacity: 0;
    transition: 1s ease all;

    &:nth-child(1),
    &:nth-child(3) {
      margin-top: 80px;
    }

    &:nth-child(1) {
      transition-delay: 1s;
    }
    &:nth-child(2) {
      transition-delay: 1.2s;
    }
    &:nth-child(3) {
      transition-delay: 1.4s;
    }
    &:nth-child(4) {
      transition-delay: 1.6s;
    }

    span {
      display: block;
      font-size: 18px;
      font-weight: 700;
      padding-top: 40px;
      margin-bottom: 14px;
      line-height: 1;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      font-weight: 600;
      font-size: 15px;
      opacity: 0.8;
      line-height: 1.1;

      li {
        margin-bottom: 5px;
      }
    }
  }
}

.active {
  .market-channels {
    > li {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

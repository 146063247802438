.profiles {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  li {
    float: left;
    text-align: center;
    width: 33.333%;
    transform: translateY(200px);
    opacity: 0;
    transition: 1s ease all;

    &:nth-child(1) {
      transition-delay: 1s;
    }
    &:nth-child(2) {
      transition-delay: 1.2s;
    }
    &:nth-child(3) {
      transition-delay: 1.4s;
    }
    &:nth-child(4) {
      transition-delay: 1.6s;
    }

    img {
      height: 200px;
      width: 200px;
      border-radius: 200px;
      margin-bottom: 20px;
    }
    span {
      display: block;
      font-weight: 600;
      font-size: 16px;
      color: rgba(255,255,255,0.7);

      &.name {
        color: #fff;
        font-size: 22px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 5px;
      }
    }
  }
}

.active {
  .profiles {
    > li {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

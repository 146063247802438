.logo {
  position: relative;
  height: 120px;
  width: 541px;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%) scale(1);

  .logo-type,
  .logo-prop {
    position: absolute;
    width: auto;
  }
  .logo-type {
    height: 120px;
  }
  .logo-prop {
    height: 164px;
    top: -23px;
    left: 253px;
    animation-name: prop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes prop {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}



.arrows {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  animation-name: arrows;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}
@keyframes arrows {
  from { opacity: 0.4; }
  to { opacity: 1; }
}

$aqua-width: 100;
$base: 4;
$item-number: 14;
$delay: 15;
$speed-min: 2;
$speed: 2;
$bg-color: rgba(255,255,255, 0.15);
$color: rgba(255,255,255,0.15);
$margin: 100%;


@mixin prefix($property, $value){
  @each $vendor in (-webkit-, -moz-, -ms-, -o-, ''){
    #{$vendor}#{$property}: $value;
  }
}

@mixin box-sizing($value){
  @each $vendor in (-moz-, ''){
    #{$vendor}box-sizing: $value;
  }
}

@mixin transition($property, $value){
  @each $vendor in (-webkit-, -moz-, -ms-, -o-, ''){
    #{$vendor}transition#{$property}: #{$vendor}#{$value};
  }
}

.bubbles {
  list-style-type: none;
  width: $aqua-width+px;
  position: absolute;
  bottom: 0;
  top: -400px;
  left: 282px;
  border-top: none;
  height: 400px;
  overflow: hidden;

  span {
    display: block;
    position: absolute;
    bottom: 0;
    background-color: $bg-color;
    border-radius: 50%;

    @include prefix(transition, opacity .4s);
    @include prefix(transition, margin .4s);

    @for $i from 0 to $item-number + 1 {

      &:nth-child(#{$i}){
        $num: $i;
        $size: $base*$num;

        @if $i > ($item-number/2 + 1) {
          $num: $item-number+1 - $i;
          $size: $base*$num;
        }

        $margin: random($aqua-width - $size);
        $anim-delay: random($delay);
        $anim-speed: $speed-min + random($speed);

        width: $size+px;
        height: $size+px;
        @include prefix(animation, move infinite linear $anim-speed+s $anim-delay+s);
        margin-bottom: -$size+px;
        margin-left: $margin+px;
      }

    }

  }

}

@keyframes move {
  0% { opacity: 0; }
  5% { opacity: 0; }
  15% { opacity: 1; }
  90% { opacity: 0.8; }
  95% { opacity: 0.5; }
  100% {
    bottom: 108%;
    opacity: 0;
  }
}

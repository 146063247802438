.prototype-phone {
  position: relative;
  float: left;
  margin: 0 auto;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.8s ease all;
  transition-delay: 1s;
  cursor: pointer;
  height: 560px;
}

.active {
  .prototype-phone {
    transform: translateY(0);
    opacity: 1;
  }
}

.prototype-content {
  position: relative;
  float: left;

  h5 {
    font-size: 48px;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 20px;


    span {
      display: block;
      opacity: 0.5;
      font-size: 75%;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      position: relative;
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 16px;
      counter-increment: step-counter;
      line-height: 60px;
      padding-left: 60px;
      color: rgba(255,255,255,0.8);

      &:before {
        position: absolute;
        color: #fff;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        content: counter(step-counter);
        height: 40px;
        width: 40px;
        border-radius: 40px;
        line-height: 40px;
        border: 1px solid rgba(255,255,255,0.5);
        text-align: center;
      }

      a {
        color: #fff;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
}

.mobile {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  margin-top: 60px;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: url('../images/bg-fallback.png') no-repeat;
  background-size: cover;
  background-position: center center;
}

.slides {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 896px;
  height: 80%;
  margin: auto;
  transition: 1s ease all;

  &.active {
    transform: translateX(0) translateY(0) scale(1);
    opacity: 1;
  }
  &.left {
    transform: translateX(-80%) translateY(-200px) scale(0.3);
    opacity: 0;
  }
  &.right {
    transform: translateX(80%) translateY(-200px) scale(0.3);
    opacity: 0;
  }
}

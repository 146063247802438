.phone-snap {
  width: auto;
  max-height: 160%;
  max-width: 100%;
  margin: auto;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
  opacity: 0.85;
  transition: 0.8s ease all;
  transition-delay: 1s;
  transform: translateY(300px);
  opacity: 0;
}

.active {
  .phone-snap {
    transform: translateY(0);
    opacity: 1;
  }
}

.solution-icons {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: -5px;

  li {
    margin: 20px 0;
    transform: translateY(200px);
    opacity: 0;
    transition: 0.8s ease all;
    line-height: 54px;
    font-size: 30px;
    font-weight: 700;
    color: rgba(255,255,255,0.7);

    img {
      display: inline-block;
      margin-right: 15px;
      height: 54px;
      vertical-align: middle;
    }

    &:nth-child(1) {
      transition-delay: 0.8s;
    }
    &:nth-child(2) {
      transition-delay: 1s;
    }
    &:nth-child(3) {
      transition-delay: 1.2s;
    }
  }
}

.active {
  .solution-icons {
    li {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

table {
  width: 100%;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.8s ease all;
  transition-delay: 0.8s;
}
th,
td {
  padding: 6px;
  font-size: 13px;
}
th {
  background-color: rgba(255,255,255,0.6);
  color: #8ad2d1;
}
td {
  font-weight: 600;
  color: rgba(255,255,255,0.9);
  background-color: rgba(255,255,255,0.15);
}

.active {
  table {
    transform: translateY(0);
    opacity: 1;
  }
}

.outings {
  position: relative;
  font-size: 170px;
  font-weight: 700;
  line-height: 250px;
  letter-spacing: -0.05em;
  padding-left: 25px;
  height: 250px;
  width: 225px;
  border-radius: 250px;
  border: 3px solid rgba(255,255,255,0.5);
  margin: 60px auto 20px auto;

  &:before {
    position: absolute;
    top: -56px;
    left: -56px;
    letter-spacing: 0;
    content: "Only";
    font-size: 24px;
    font-weight: 700;
    color: rgba(255,255,255,0.8);
    text-align: center;
    line-height: 90px;
    height: 90px;
    width: 90px;
    border: 3px solid rgba(255,255,255,0.5);
    border-radius: 90px;
    text-transform: uppercase;
  }
}

.outings-sub {
  font-size: 80px;
  font-weight: 700;
  text-align: center;
  transform: translateY(200px);
  opacity: 0;
  transition: 0.8s ease all;
  transition-delay: 0.8s;

  span {
    display: block;
    font-size: 34px;
    opacity: 0.5;
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.active {
  .outings-sub {
    transform: translateY(0);
    opacity: 1;
  }
}
